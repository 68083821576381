import React, { Component } from 'react';
import Stepper from './Stepper'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'



export class FormUserDetails extends Component {

 
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

 
  render() {
    const { values, handleChange, blurHandler } = this.props;
    const { firstName, lastName, email, phone} = values
    const isDisabled = Boolean(firstName.error || lastName.error || email.error || phone.error || !firstName.value || !lastName.value || !email.value || !phone.value)

    return (
    <div className='multistep-form'>
                <Stepper step={this.props.step} />
                <h3>
                    Tell us more about your business, enter personal information
                </h3>
        <div className='form__row'>
            <label htmlFor='firstName'>
            First Name
            </label>
            <input 
                type="text" 
                required={firstName.error}
                placeholder='Enter your name' 
                name="firstName" 
                maxLength="20"
                className='input'
                onChange={handleChange()}
                value={firstName.value} 
                onBlur={blurHandler}/>
            {firstName.error && <div className='errorText' style={{color:'red'}}>{firstName.error}</div>}
        </div>

        <div className='form__row'>
            <label htmlFor='lastName'>
            Last Name
            </label>
            <input 
                type="text" 
                required={lastName.error}
                placeholder='Enter your last name' 
                name="lastName" 
                maxLength="20"
                className='input' 
                onChange={handleChange()}
                value={lastName.value} 
                onBlur={blurHandler}/>
            {lastName.error && <div className='errorText' style={{color:'red'}}>{lastName.error}</div>}
        </div>

        <div className='form__row'>
            <label htmlFor='email'>
            Email
            </label>
            <input 
                type="text" 
                placeholder='Enter your email' 
                name="email" 
                required={email.error}
                className='input' 
                onChange={handleChange()}
                value={email.value} 
                onBlur={blurHandler}/>
            {email.error && <div className='errorText' style={{color:'red'}}>{email.error}</div>}
        </div>

        <div className='form__row'>
            <label htmlFor='phone'>
            Phone
            </label>
            <PhoneInput
                international
                defaultCountry='UA'
                name="phone"
                placeholder='phone number' 
                className='input' 
                onChange={handleChange('phone')}
                value={phone.value}
                style={{ border:phone.error && '1px solid red'}}
                onBlur={blurHandler}/>
            {phone.error && <div className='errorText' style={{color:'red'}}>{phone.error}</div>}
        </div>
        <div className='centered step__buttons'>
        <button type='button' className="digimeth-btn default-btn xl" onClick={this.continue} disabled={isDisabled}>
            Next Step
        </button>
        </div>
  </div>
    );
  }
}

export default FormUserDetails;

  //   <MuiThemeProvider>
    //     <>
    //       <Dialog
    //         open
    //         fullWidth
    //         maxWidth='sm'
    //       >
    //         <AppBar title="Enter User Details" />
    //         <TextField
    //           placeholder="Enter Your First Name"
    //           label="First Name"
    //           onChange={handleChange('firstName')}
    //           defaultValue={values.firstName}
    //           margin="normal"
    //           fullWidth
    //         />
    //         <br />
    //         <TextField
    //           placeholder="Enter Your Last Name"
    //           label="Last Name"
    //           onChange={handleChange('lastName')}
    //           defaultValue={values.lastName}
    //           margin="normal"
    //           fullWidth
    //         />
    //         <br />
    //         <TextField
    //           placeholder="Enter Your Email"
    //           label="Email"
    //           onChange={handleChange('email')}
    //           defaultValue={values.email}
    //           margin="normal"
    //           fullWidth
    //         />
    //         <br />
    //         <Button
    //           color="primary"
    //           variant="contained"
    //           onClick={this.continue}
    //         >Continue</Button>
    //       </Dialog>
    //     </>
    //   </MuiThemeProvider>