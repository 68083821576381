import React, { Component } from 'react';
import Stepper from './Stepper'

const paymentList = [
  '0-100К $',
  '100K - 500K $',
  '500K - 1M $',
  '1M - 10M $',
  '10M - 50M $',
  '50M+ $']

const industryList  = [
  'Saas company',
  'E-com acquiring',
  'Retail acquiring',
  'Gambligng and High Risk',
  'Dating',
  'Education startups',
  'Mobile Apps'
]

export class Confirm extends Component {

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange, blurHandler } = this.props;
    const {payment, industry} = values;
    const isDisabled = Boolean(payment.error ||  industry.error || !payment.value || !industry.value )
    return (
    <div className='multistep-form'>
      <Stepper step={this.props.step} />

       <h3>
        Enter company volume info
        </h3>

        <br/>


        <div className='form__row'>
            <label htmlFor='payment'>
            Annual Payment Volume
            </label>
            <select required={payment.error} className='input' onBlur={blurHandler} name="payment" onChange={handleChange()}>
                <option value=''>Select your payment</option>
                {paymentList.map((val,index) => <option value={`${val}`} key={index}>{val}</option>)}
            </select>
            {payment.error && <div className='errorText' style={{color:'red'}}>{payment.error}</div>}
        </div>

        <div className='form__row'>
            <label htmlFor='industry'>
            Your industry
            </label>
            <select required={industry.error} className='input' onBlur={blurHandler} name="industry" onChange={handleChange()}>
                <option value=''>Select your industry</option>
                {industryList.map((val,index) => <option value={`${val}`} key={index}>{val}</option>)}
            </select>
            {industry.error && <div className='errorText' style={{color:'red'}}>{industry.error}</div>}
        </div>

        <div className='split step__buttons'>
        <button type='button' className="digimeth-btn transparent-btn--black xl" onClick={this.back}>
          Back
       </button>
       <button type='button' className="digimeth-btn default-btn xl" onClick={this.continue} disabled={isDisabled}>
       Next
       </button>
        </div>

    </div>
    );
  }
}

export default Confirm;