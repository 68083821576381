import React from "react"

const Stepper = ({step}) => {

  return (
    <div className="stepper">
      <div className="stepper-step">
        <div className="top">
          <p className="step-row disabled"></p>
          <div className="circule centered active">
            <span className="center"></span>
          </div>

          <p className={ step === 2 || step === 3 ? 'step-row active' : 'step-row' }></p>
        </div>

        <span className="active">Personal</span>
      </div>
      <div className="stepper-step">
        <div className="top">
          <p className={ step === 2 || step === 3 ? 'step-row active' : 'step-row' }></p>
          <div className={ step === 2 || step === 3 ? 'circule centered active' : 'circule centered' }>
            <span className="center"></span>
          </div>
          <p className={ step === 3 ? 'step-row active' : 'step-row' }></p>
        </div>
        <span className={ step === 2 || step === 3 ? 'active' : '' }>Company</span>
      </div>
      <div className="stepper-step">
        <div className="top">
          <p className={ step === 3 ? 'step-row active' : 'step-row' }></p>
          <div className={ step === 3 ? 'circule centered active' : 'circule centered' }>
            <span className="center"></span>
          </div>
          <p className="step-row disabled"></p>
        </div>

        <span className={ step === 3 ? 'active' : '' }>Volume</span>
      </div>
    </div>
  )
}

export default Stepper
