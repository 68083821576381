import React, { Component } from 'react';
import {Link} from 'gatsby'

import image from '../../../images/succes.svg'

export class Success extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
    //   <MuiThemeProvider>
    //     <>
    //       <Dialog
    //         open
    //         fullWidth
    //         maxWidth='sm'
    //       >
    //         <AppBar title="Success" />
    //         <h1>Thank You For Your Submission</h1>
    //         <p>You will get an email with further instructions.</p>
    //       </Dialog>
    //     </>
    //   </MuiThemeProvider>

        <div className="multistep-form">



          <div className="status-icon">
            <img src={image} alt="error" />
          </div>

          <h3 className="status-title">Thank you for your application!</h3>

          <p className="status-description">Our managers will contact you shortly</p>

          <div className="centered">
            <Link to="/">
              <button type="button"  className="digimeth-btn default-btn xl">Back to Homepage</button>
            </Link>
          </div>
        </div>
    );
  }
}

export default Success;



