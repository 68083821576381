import React, { Component } from 'react';

import FormUserDetails from './FormUserDetails';

import FormPersonalDetails from './FormPersonalDetails';
import Confirm from './Confirm';
import Success from './Success';

import './form.css'

export class UserDetails extends Component {
  state = {
    step: 1,
    firstName: {value:'', error:'', required:true},
    lastName: {value:'', error:'', required:true},
    email: {value:'', error:'', required:true, validFunc:(val)=>{
      const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      return (!re.test(String(val).toLowerCase()))?'Incorrect email':''}},
    phone:{value:'', error:'', required:true, validFunc:(val)=>{
      const re =/^[\d\+][\d\(\)\ -]{4,14}\d$/
      return (!re.test(String(val).toLowerCase()))?'Incorrect phone':''}},
    companyName:{value:'', error:'', required:true},
    companyWebsite:{value:'', error:'', required:true},
    country:{value:'', error:'', required:true},
    industry:{value:'', error:'', required:true},
    payment:{value:'', error:'', required:true}
    /*occupation: {value:'', error:'', required:true, validFunc:(val)=>{}},
    city: {value:'', error:'', required:true, validFunc:(val)=>{}},
    bio: {value:'', error:'', required:true, validFunc:(val)=>{}}, */
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change
  handleChange = (name) => (e) => {
    if(e && e.target){
      this.setState({ [e.target.name]:{ ...this.state[e.target.name], value: e.target.value }});
    }else{
      this.setState({ [name]:{ ...this.state[name], value: e }});
    }
    
  };

  blurHandler = e =>{
    if(this.state[e.target.name].required && e.target.value === ''){
      this.setState({ [e.target.name]:{ ...this.state[e.target.name], error: 'This field is required' }})
    }else{
      const errorFn = this.state[e.target.name].validFunc
      this.setState({ [e.target.name]:{ ...this.state[e.target.name], error:errorFn?errorFn(e.target.value):''}})
    }
  }

  render() {
    const { step } = this.state;
    const { firstName, lastName, email, phone, companyName, companyWebsite, country, industry, payment } = this.state;
    const values = { firstName, lastName, email, phone, companyName, companyWebsite, country, industry, payment  };

    switch (step) {
      case 1:
        return (
          <FormUserDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            blurHandler={this.blurHandler}
            values={values}
            step={this.state.step}
          />
        );
      case 2:
        return (
          <FormPersonalDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            blurHandler={this.blurHandler}
            values={values}
            step={this.state.step}
          />
        );
      case 3:
        return (
          <Confirm
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            handleChange={this.handleChange}
            blurHandler={this.blurHandler}
            step={this.state.step}
          />
        );
      case 4:
        return <Success />;
      default:
        /*(console.log('This is a multi-step form built with React.'))*/
        return null
    }
  }
}

export default UserDetails;