import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import UserDetails from "../ui/form/multiForm/UserDetails";

const ContactPage = () => {
  return (
    <>
     <SEO title="Contact - Digimeth.net" />
     <Layout footer={`no`} hiddenContactBtn >
      <section
        className="typical__padding"
        style={{
          minHeight: `100vh`,
          paddingBottom: `100px`,
        }}
      >
        <div className="site__container">
          <UserDetails />
        </div>
      </section>
    </Layout>
    </>
   
  );
};

export default ContactPage;
