import React, { Component } from 'react'
import Stepper from './Stepper'

export class FormPersonalDetails extends Component {

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange, blurHandler } = this.props;
    const { companyName, companyWebsite, country } = values;
    const isDisabled = Boolean(companyName.error ||  companyWebsite.error ||  country.error  || !companyName.value || !companyWebsite.value || !country.value )
    return (
      <div className='multistep-form'>
        <Stepper step={this.props.step} />
        <h3>Enter company information</h3>
        <div className='form__row'>
            <label htmlFor='companyName'>
            Company Name
            </label>
            <input 
              type="text" 
              placeholder='Company Name' 
              name="companyName" 
              required={companyName.error}
              className='input'
              onChange={handleChange()}
              value={companyName.value}
              onBlur={blurHandler}/>
              {companyName.error && <div className='errorText' style={{color:'red'}}>{companyName.error}</div>}
        </div>

        <div className='form__row'>
            <label htmlFor='companyWebsite'>
            Company Website
            </label>
            <input 
              type="text" 
              placeholder='Enter your name' 
              name="companyWebsite" 
              required={companyWebsite.error}
              className='input'
              onChange={handleChange()}
              value={companyWebsite.value}
              onBlur={blurHandler}/>
              {companyWebsite.error && <div className='errorText' style={{color:'red'}}>{companyWebsite.error}</div>}
        </div>

        <div className='form__row'>
            <label htmlFor='country'>
            Country
            </label>
              <select required={country.error} className='input' onBlur={blurHandler} name="country" onChange={handleChange()}>
                <option value=''>Select your country</option>
                <option value='Ukraine'>Ukraine</option>
                <option value='USA'>USA</option>
              </select>
                {country.error && <div className='errorText' style={{color:'red'}}>{country.error}</div>}
        </div>

        <div className='split step__buttons'>
        <button type='button' className="digimeth-btn transparent-btn--black xl" onClick={this.back}>
          Back
       </button>
       <button type='button' className="digimeth-btn default-btn xl" onClick={this.continue} disabled={isDisabled}>
       Next step
       </button>
        </div>
     
      </div>
    );
  }
}

export default FormPersonalDetails;
  // <MuiThemeProvider>
      //   <>
      //     <Dialog
      //       open
      //       fullWidth
      //       maxWidth='sm'
      //     >
      //       <AppBar title="Enter Personal Details" />
      //       <TextField
      //         placeholder="Enter Your Occupation"
      //         label="Occupation"
      //         onChange={handleChange('occupation')}
      //         defaultValue={values.occupation}
      //         margin="normal"
      //         fullWidth
      //       />
      //       <br />
      //       <TextField
      //         placeholder="Enter Your City"
      //         label="City"
      //         onChange={handleChange('city')}
      //         defaultValue={values.city}
      //         margin="normal"
      //         fullWidth
      //       />
      //       <br />
      //       <TextField
      //         placeholder="Enter Your Bio"
      //         label="Bio"
      //         onChange={handleChange('bio')}
      //         defaultValue={values.bio}
      //         margin="normal"
      //         fullWidth
      //       />
      //       <br />

      //       <Button
      //         color="secondary"
      //         variant="contained"
      //         onClick={this.back}
      //       >Back</Button>

      //       <Button
      //         color="primary"
      //         variant="contained"
      //         onClick={this.continue}
      //       >Continue</Button>
      //     </Dialog>
      //   </>
      // </MuiThemeProvider>